<template lang="pug">
v-container.systemTags
    v-row
        v-spacer
        v-col(cols='4')
            v-card(light)
                v-card-title.brand.white--text.py-2 System Tags
                v-card-text
                    v-simple-table.tagsTable.striped.hoverable(dense)
                        template(v-slot:default)
                            thead
                                tr
                                    th Tag Name
                                    th Template Count
                            tbody
                                tr(v-for='tag in system_tags', :key='tag.id', @click='')
                                    td
                                        span(v-if='!isEditingTag(tag)', @click='editTag(tag)', title='Click To Edit') {{ tag.display }}
                                        v-text-field(v-if='isEditingTag(tag) && editingTag', v-model='tag.display', @keyup.esc='cancelEditTag', @keyup.enter='doEditTag(tag)', autofocus, dense, label='Tag Display Name', color='brand', single-line, :error-messages='editTagErrors')
                                    td
                                        span(title='Number of templates that use this tag') {{tag.templates.length}}
                                        v-btn.ml-1(v-if='isEditingTag(tag) && canDelete(tag)', color='red', disabled, title='Cannot delete system tags', small, icon, @click='doEditTag(tag)')
                                            v-icon(small) delete
                                    td
                                        v-btn(v-if='!isEditingTag(tag)', small, icon, @click='editTag(tag)', title='Edit')
                                            v-icon(small) edit
                                        v-btn(v-if='isEditingTag(tag)', x-small,dark, title='Move to regular tags', @click='removeSystemFlag(tag)') Unlock
                                        v-btn.ml-1(v-if='isEditingTag(tag)', color='green', title='Save Tag', small, icon, @click='doEditTag(tag)')
                                            v-icon(small) save
                                        v-btn(v-if='isEditingTag(tag)', small, icon, title='Cancel Editing', @click='cancelEditTag')
                                            v-icon(small) close
        v-col(cols='4')
            v-card(light)
                v-card-title.brand.white--text.py-2 Regular Tags
                v-card-text
                    v-simple-table.tagsTable.striped.hoverable(dense)
                        template(v-slot:default)
                            thead
                                tr
                                    th Tag Name
                                    th Template Count
                            tbody
                                tr(v-for='tag in reg_tags', :key='tag.id', @click='')
                                    td
                                        span(v-if='!isEditingTag(tag)', @click='editTag(tag)', title='Click To Edit') {{ tag.display }}
                                        v-text-field(v-if='isEditingTag(tag) && editingTag', v-model='tag.display', @keyup.esc='cancelEditTag', @keyup.enter='doEditTag(tag)', autofocus, dense, label='Tag Display Name', color='brand', single-line, :error-messages='editTagErrors')
                                    td
                                        span(title='Number of templates that use this tag') {{tag.templates.length}}
                                        v-btn.ml-1(v-if='isEditingTag(tag) && canDelete(tag)', color='red', title='Delete Tag', small, icon, @click='deleteTag(tag)')
                                            v-icon(small) delete
                                    td
                                        v-btn(v-if='!isEditingTag(tag)', small, icon, @click='editTag(tag)', title='Edit')
                                            v-icon(small) edit
                                        v-btn(v-if='isEditingTag(tag)', x-small,dark, title='Move to system tags', @click='addSystemFlag(tag)') Lock
                                        v-btn.ml-1(v-if='isEditingTag(tag)', color='green', title='Save Tag', small, icon, @click='doEditTag(tag)')
                                            v-icon(small) save
                                        v-btn(v-if='isEditingTag(tag)', small, icon, title='Cancel Editing', @click='cancelEditTag')
                                            v-icon(small) close
        v-spacer
    v-overlay(absolute, :value='busy')
        .text-h6 Please Wait...
        v-progress-linear(indeterminate)
</template>

<script>
import _ from 'lodash';
import dti from 'dti';

export default {
    data () {
        return {
            dti,
            accessKey: 'sattags',
            message: '',
            busy: false,
            tagData: [],
            allTags: [],
            allTemplateTags: [],
            editingTag: null,
            editTagErrors: [],
        };
    },
    methods: {
        getData () {
            this.socketEmit('getTags', null, (results) => {
                this.allTags = results.filter(tag => tag.active);
            });

            this.socketEmit('getTemplateTags', null, (results) => {
                this.allTemplateTags = results.filter(templateTag => templateTag.active);
            });
        },
        sortTags (tagArray) {
            tagArray.sort(function (a, b) {
                var textA = a.display.toLowerCase();
                var textB = b.display.toLowerCase();
                return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
            });

            return tagArray;
        },
        editTag (tag) {
            this.editTagErrors = [];
            this.editingTag = _.clone(tag);
        },
        isEditingTag (tag) {
            return this.editingTag?.id === tag.id;
        },
        cancelEditTag () {
            this.editingTag = null;
        },
        getTagTemplates () {
            for(let tag of this.allTags) {
                let templateTags = this.allTemplateTags.filter(templateTag => templateTag.tag_id == tag.id);
                let templates = templateTags.map(template => template.template_id);
                tag.templates = templates;
            }
        },
        async doEditTag (tag) {
            let result = await this.sendCommand({
                action: 'updateTag',
                parameters: tag
            });
            if (result) {
                this.message = `"${tag.display}" updated`;
            } else {
                console.log('error updating tag');
                this.message = `Error updating "${tag.display}"`;
            }
            this.editingTag = null;
        },
        async deleteTag (tag) {
            let result = await this.sendCommand({
                action: 'deleteTag',
                parameters: tag
            });
            if (result) {
                this.message = `"${tag.display}" removed`;
                this.getData();
            } else {
                console.log('error removing tag');
                this.message = `Error removing "${tag.display}"`;
            }
            this.editingTag = null;
            return;
        },
        removeSystemFlag (tag) {
            if(tag) {
                tag.system_tag = false;
            }
            this.doEditTag(tag);
            this.editingTag = null;
        },
        addSystemFlag (tag) {
            if(tag) {
                tag.system_tag = true;
            }
            this.doEditTag(tag);
            this.editingTag = null;
        },
        getTemplates (tag) {
            if(tag) {
                let templateTags = this.allTemplateTags.filter(templateTag => templateTag.template_id);
                if(templateTags) {
                    if(templateTags.length == 0) {
                        return [];
                    }
                    else {
                        return templateTags;
                    }
                }
            }
            return [];
        },
        canDelete (tag) {
            if(tag) {
                if(tag.templates) {
                    if(tag.templates.length == 0) {
                        return true;
                    }
                }
                else { //No template tags attached to object, check again
                    if(this.getTemplates(tag).length == 0) {
                        return true;
                    }
                }
            }
            return false;
        },
    },
    computed: {
        system_tags () {
            this.getTagTemplates();
            let sTags = this.allTags.filter(tag => tag.system_tag);
            sTags = this.sortTags(sTags);

            if(sTags) {
                return sTags;
            }
            else {
                return [];
            }
        },
        reg_tags () {
            this.getTagTemplates();
            let rTags = this.allTags.filter(tag => !tag.system_tag);
            rTags = this.sortTags(rTags);

            if(rTags) {
                return rTags;
            }
            else {
                return [];
            }
        },


        showAlert: {
            get () {
                return this.message !== '';
            },
            set (val) {
                if (val === false) {
                    this.message = '';
                }
            }
        },
    },
    watch: {
    },
    mounted () {
        this.getData();


        window.satsystemtagsvm = this;
    }
};
</script>

<style lang="scss" scoped>


</style>

<style lang="scss">
.systemTags {
    .tagsTable {
        td {
            position: relative;
        }
        .nameInput {
            width: 100px;
        }
        .systemTag {
            width: 300px;
        }
    }
}
</style>
